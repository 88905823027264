html,
#root {
    height: 100%;
    overflow-x: hidden;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
    background-image: url('bg.jpg');
    background-size: cover;
    font-family: 'PT Sans';
    font-weight: 200;
    color: white;
    overflow-x: hidden;
}

input,
button {
    font-family: 'PT Sans';
}
