.swipe-enter {
    left: 20%;
    opacity: 0;
    z-index: 1;
}

.swipe-enter-active {
    opacity: 1;
    transform: translateX(-20%);
    transition: transform 500ms, opacity 400ms ease;
}

.swipe-exit {
    opacity: 1;
    z-index: 0;
}

.swipe-exit-active {
    opacity: 0;
    transform: translateX(-10%);
    transition: transform 500ms, opacity 400ms ease;
}
